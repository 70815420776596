import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, campanhaStore } from '@/store';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import FiltroPorTabela from '@/components/FiltrosPorTabela.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import _ from 'lodash';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Nome', value: 'nome', show: true },
            { text: 'Descrição', value: 'descricao', show: true },
            { text: 'Data de início', value: 'data_inicio', show: true },
            { text: 'Data de término', value: 'data_termino', show: true },
        ];
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
        this.tabelaRef = null;
        this.items = [];
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Campanhas) {
            return userToolStore.userToolsIndexed.Campanhas[0].allow_view;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Campanhas) {
            return userToolStore.userToolsIndexed.Campanhas[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Campanhas) {
            return userToolStore.userToolsIndexed.Campanhas[0].allow_delete;
        }
    }
    get headersFiltered() {
        return _.filter(this.headers, (item) => item.show);
    }
    showFiltroPorTabela() {
        const form = this.$refs.filtroPorTabela;
        form.show('parceria');
    }
    async onUpdateHeaders(headers) {
        this.headers = headers;
    }
    async created() {
        this.$nextTick(() => {
            this.tabelaRef = this.$refs.tabela;
        });
    }
    async mounted() {
        this.loading = true;
        this.items = await campanhaStore.getCampanhas();
        this.loading = false;
    }
};
List = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            FiltroPorTabela,
            ImprimirTabelaComponent,
            TextExpandableComponent,
        },
    })
], List);
export default List;
